// stylelint-disable declaration-no-important

//
// Text
//
$font-family-monospace: '';
$font-weight-light: 200;
$font-weight-lighter: 100;
$font-weight-normal: 500;
$font-weight-bold : 700;
$font-weight-bolder: 800;
$black: black;
$white: white;
$body-color: black;
$text-muted: gray;
$alpha: 0.8;

.text-monospace { font-family: $font-family-monospace !important; }

// Alignment

.text-justify  { text-align: justify !important; }
.text-wrap     { white-space: normal !important; }
.text-nowrap   { white-space: nowrap !important; }

// Responsive alignment

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-light   { font-weight: $font-weight-light !important; }
.font-weight-lighter { font-weight: $font-weight-lighter !important; }
.font-weight-normal  { font-weight: $font-weight-normal !important; }
.font-weight-bold    { font-weight: $font-weight-bold !important; }
.font-weight-bolder  { font-weight: $font-weight-bolder !important; }
.font-italic         { font-style: italic !important; }

// Contextual colors

.text-white { color: $white !important; }

.text-body { color: $body-color !important; }
.text-muted { color: $text-muted !important; }

.text-black-50 { color: $black !important; }
.text-white-50 { color: $white !important; }

// Misc

.text-decoration-none { text-decoration: none !important; }

.text-break {
  word-break: break-word !important; // IE & < Edge 18
  overflow-wrap: break-word !important;
}

// Reset

.text-reset { color: inherit !important; }
