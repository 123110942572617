.MuiDrawer-paperAnchorRight {
  left: 10% !important;
}
.react-confirm-alert-overlay {
  z-index: 2000;
  background: rgba(0, 0, 0, 0.2);
}

.react-confirm-alert-button-group > button {
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
  height: 33px;
  margin: 0px 0px 0px 10px;
  color: #fff;
  background-color: #1a237e;
  padding: 8px 22px;
  display: inline-flex;
  align-items: center;
}

.react-confirm-alert-button-group {
  justify-content: flex-end;
}
.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px 10px 10px 20px;
  text-align: left;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.43);
  color: #263238;
}
.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 1.5em;
}
