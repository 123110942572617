.my-swal-title-class {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.my-swal-text-class {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.swal2-container {
  z-index: 99999999 !important;
}

.state {
  width: 300px;
  padding: 5px;
}

.ant-table-filter-dropdown {
  z-index: 99999;
  position: relative;
}

.ant-dropdown {
  z-index: 999999;
}
/* .ant-table-thead tr {
display: flex;
}
.ant-table-thead th {
flex: 1;
}
.ant-table-row {
display: flex;
}
.ant-table-row td {
flex: 1;
overflow: auto;
}
.ant-table-row td::-webkit-scrollbar {
display: none;
}
.columns {
display: flex;
align-items: center;
width: 200px;
} */
