
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.page-section {
  padding: 0;
}

.page-header {
  padding: 4rem 0 5rem;
}

.main {
  min-height: 56vh;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.btn-border {
}

.relative {
  position: relative;
}

.btn {
  font-size: 12px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.btn-block {
  font-size: 16px;
}

.btn-green {
  background-color: #3f51b5 !important;
  color: #fff;
}

.btn-green:hover {
  background: #3f51b5;
  color: #fff !important;
}

.btn--rounded {
  border-radius: 50px;
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.rounded {
  border-radius: 50px;
}

.ico {
  width: 1rem;
}

.col-center {
  margin: 0 auto;
  float: none !important;
}

/* Masthead */
.masthead {
  height: auto;
  padding-top: 72px;
  // background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url("img/bg-masthead.jpg");
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url("img/bg-masthead.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
  width: 100%;
}

.home .masthead p {
  font-size: 1rem;
}

.home .masthead span {
  font-size: 1.5rem;
  font-weight: 800;
}

.masthead span {
  font-size: 2.5rem;
  font-weight: 800;
}

.masthead h1 {
  font-size: 32px;
  text-align: left;
  z-index: 999;
  text-shadow: 1px 1px #111111;
}

.masthead h6 {
  z-index: 999;
  color: rgba(255, 255, 255, 0.9) !important;
  text-shadow: 2px 1px #111111;
  font-weight: 400 !important;
  line-height: 1.75;
}

@media (min-width: 992px) {
  .masthead {
    height: 525px;
    padding-top: 72px;
    padding-bottom: 0;
  }
  .masthead h1 {
    font-size: 40px;
    text-align: center;
  }
  .masthead span {
    font-size: 1.5rem;
    font-weight: 800;
  }
}

@media (min-width: 1200px) {
  .masthead span {
    font-size: 2.5rem;
    font-weight: 800;
  }
}

/* end - Masthead */

/* subscribe */

.subscribe h3 {
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0 0 2.5rem 0;
}

.subscribe p {
  margin: 0 0 2.5rem 0;
}

.phone {
  position: relative;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 20px 25px;
}

.phone p {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0px;
}

.phone a {
  color: #3f51b5;
  font-size: 1.5rem;
}

.phone a:hover {
  text-decoration: none;
  color: #3f51b5;
}

.phone img {
  width: 6%;
}

.form-style-1 {
  background: #fff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border: 1px solid #dee2e6;
}

.form-style-1 .form-control {
  background-color: #f8f9fa;
  border: rgba(0, 0, 0, 0.3) 1px solid;
  padding: 12px 15px;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  height: auto;
  letter-spacing: 1px;
}

.form-style-1 .form-control:focus {
  outline: none;
  border: rgba(0, 0, 0, 0.5) 2px solid;
  color: rgba(0, 0, 0, 0.5);
}

.form-style-1 .btn {
  padding: 12px 15px;
  border-radius: 0;
}

.form-style-1 .btn-block {
  font-size: 16px;
}

@media (min-width: 992px) {
  .subscribe h3 {
    font-weight: bold;
    font-size: 2.5rem;
    margin: 0 0 1.5rem 0;
  }

  .subscribe p {
    margin: 0 0 2.5rem 0;
  }

  .phone p {
    margin: 0px;
  }
}

@media (min-width: 1200px) {
  .subscribe h3 {
    font-weight: bold;
    font-size: 2.5rem;
    margin: 0 0 2.5rem 0;
  }
}

/* end - subscribe */

hr.divider {
  border-width: 0.1rem;
  border: rgba(0, 0, 0, 0.1) 1px solid;
}

.container .divider {
  border-width: 0.1rem;
  border: rgba(119, 119, 119, 0.1) 1px solid;
  margin: 10px 0;
}

/* youtube */

.page-section-youtube {
  padding: 1rem 0;
}

iframe {
  width: 100%;
  height: 600px;
}

/* end - youtube */

/* testimonials */

.page-section.testimonials {
  background-color: #f8f9fa;
  padding-top: 150px;
  padding-bottom: 150px;
}

.testimonials h2 img {
  width: 2rem;
}

.testimonials h2 {
  padding: 0 15px;
}

.carousel {
  margin: 0 auto;
}

.carousel .item {
  color: #999;
  overflow: hidden;
  font-size: 13px;
}

.carousel .media {
  position: relative;
  padding: 0 0 0 20px;
}

.carousel .testimonial-wrapper {
  padding: 0 10px;
}

.carousel .testimonial {
  font-size: 14px;
  color: #808080;
  position: relative;
  padding: 30px 15px;
  margin-bottom: 15px;
}

.carousel .overview {
  padding: 3px 0 0 0px;
}

.carousel .overview .addres {
  padding: 5px 0 8px;
}

.carousel .overview span {
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
}

.carousel .carousel-indicators {
  bottom: -70px;
}

.carousel-indicators {
  position: absolute;
  right: 10 !important;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  /* padding-left: 0; */
  /* margin-right: 15%; */
  /* margin-left: 15%; */
  list-style: none;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 1px 2px;
}
.carousel-indicators li {
  width: 12px !important;
  height: 12px !important;
  background: #e2e2e2;
  border: 1px solid #ccc !important;
  border-radius: 50% !important;
}
.carousel-indicators li.active {
  color: #fff;
  background: #1abc9c;
  border: 1px solid #fff;
}

/* end - testimonials */

/* footer */

footer ul {
  list-style: none;
}

.list-inline .list-inline-item {
  font-size: 0.5rem;
}

/* end - footer */

/* home */

/* Masthead */

.home .masthead,
.page-template-template-state .masthead,
.page-template-template-city .masthead {
  height: auto;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url('img/home-for-sale.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
}

.home .masthead span,
.page-template-template-state .masthead span,
.page-template-template-city .masthead span {
  font-size: 2.5rem;
  font-weight: 800;
}

.home .searchbar,
.page-template-template-state .searchbar,
.page-template-template-city .searchbar {
  background-color: #0c160f;
  padding: 15px;
  margin-bottom: 15px;
}

.home .searchbar input,
.page-template-template-state .searchbar input,
.page-template-template-city .searchbar input {
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  background-color: #fff;
}

.home .searchbar .btn,
.page-template-template-state .searchbar .btn,
.page-template-template-city .searchbar .btn {
  width: 100%;
}

.home .searchbar .btn,
.page-template-template-state .searchbar .btn,
.page-template-template-city .searchbar .btn {
  margin: 0;
}

@media (min-width: 992px) {
  .home .searchbar,
  .page-template-template-state .searchbar,
  .page-template-template-city .searchbar {
    background-color: #fff;
    border-radius: 50rem;
    padding: 16px;
    z-index: 999;
    box-shadow: 0px 13px 15px -2px #d6d6d6;
    min-width: 80%;
    transform: translateY(50%);
    margin-bottom: 0;
  }

  .home .searchbar form,
  .page-template-template-state .searchbar form,
  .page-template-template-city .searchbar form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .home .searchbar input,
  .page-template-template-state .searchbar input,
  .page-template-template-city .searchbar input {
    border: none;
    margin: 0;
    line-height: 38px;
  }

  .home .searchbar .btn,
  .page-template-template-state .searchbar .btn,
  .page-template-template-city .searchbar .btn {
    margin: 0;
    border-radius: 50px;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 38px;
  }

  .searchbar i {
    padding-top: 10px;
    padding-left: 20px;
    color: #828282;
  }
}

.search_input {
  padding: 0 10px;
  color: #333;
  border: 0;
  outline: 0;
  background: none;
  width: 450px;
  line-height: 38px;
  transition: width 0.4s linear;
}

@media (max-width: 992px) {
  .search_input {
    padding: 0 10px;
    width: 250px;
    line-height: 40px;
    transition: width 0.4s linear;
  }
}

@media (min-width: 1200px) {
  .home header.masthead span,
  .page-template-template-state header.masthead span,
  .page-template-template-city header.masthead span {
    font-size: 2.5rem;
    font-weight: 800;
  }
}

/* end - masterhead */

.page-section.webuyhouse {
  background-color: #f8f9fa;
}

@media (min-width: 992px) {
  .page-section.webuyhouse {
    padding-top: 180px;
    padding-bottom: 185px;
  }
}

/* buyhouse */

.page-section.buyhouse {
  background-image: url('img/auth.jpg');
  background-size: 100% 300px;
  background-position: top;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 320px;
}

.buyhouse h2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.buyhouse .border-right {
  border-color: #73c983 !important;
}

@media (min-width: 768px) {
  .page-section.buyhouse {
    background-size: 50% auto;
    background-position: right;
    padding: 0;
  }

  .buyhouse h2 {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

/* end - buyhouse */

/* steps */

.page-section.steps-section {
  padding-top: 100px;
  padding-bottom: 120px;
}

.section-header,
.steps-header,
.steps-name {
  font-weight: 500;
  font-size: 1.2em;
}

.steps-name {
  margin-top: 0;
  padding-top: 16px;
}

.steps-header {
  margin-bottom: 20px;
  text-align: center;
}

.steps-timeline {
  border-left: 2px dashed #3f51b5;
  margin-left: 31px;
}

.text-center {
  text-align: center!important;
}

.step-number {
  line-height: 61px;
  text-align: center;
  color: #fff;
  background-color: #3f51b5;
  width: 61px;
  height: 61px;
  border-radius: 50%;
  font-size: 24px;
  float: left;
  margin-left: -31px;
  margin-right: 10px;
}

.steps-one,
.steps-two {
  padding-bottom: 40px;
}

.steps-description {
  margin: 0;
  font-size: 0.9rem;
  overflow: hidden;
}

@media (min-width: 768px) {
  .steps-one,
  .steps-two,
  .steps-three {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 33.33333%;
  }

  .steps-one {
    transform: translateX(-50%);
  }
  .steps-three {
    transform: translateX(50%);
  }

  .steps-timeline {
    border-left: none;
    margin-left: 0;
    border-top: 2px dashed #3f51b5;
    padding-top: 0px;
    margin-top: 31px;
    max-width: 75%;
    margin: 31px auto 0;
  }

  .step-number {
    float: none;
    margin: -31px auto 40px;
  }

  .steps-name {
    text-align: center;
  }

  .steps-description {
    text-align: justify;
    word-spacing: -2px;
  }
}

/* end steps */

/* different */

.different {
  background-color: #f8f9fa;
  padding-top: 100px;
  padding-bottom: 120px;
}

.different .card-group ul {
  font-size: 0.95rem;
}

.different .card-group ul li {
  margin-bottom: 10px;
  line-height: 2rem;
}

.different .card-group .shadow-lg {
  z-index: 999;
}

.different .card-group {
  color: rgba(100, 100, 100, 0.9);
}

.different .card-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.different--agent {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.list1 ol li {
  padding: 0px 0px 10px 25px;
}
.list1 ol li:last-child {
  padding-bottom: 0px;
}
.list1 ol li:before {
  content: '|';
  color: #3f51b5;
  position: absolute;
  font-weight: 900;
  margin: 0px 0px 0px -15px;
}

.list2 ol li {
  padding: 0px 0px 10px 25px;
}
.list2 ol li:last-child {
  padding-bottom: 0px;
}
.list2 ol li:before {
  content: ' ';
  background-color: #3f51b5;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  position: absolute;
  font-weight: 900;
  margin: 7px 0px 0px -15px;
}
/* end - content - post */

/* cta- newsletter */

.subscribe-wrapper .subscribe-form input {
  background: none;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  height: 57px;
  margin-right: 17px;
  padding-left: 35px;
  width: 70%;
}

.subscribe-wrapper .subscribe-form button {
  background: #ffff;
  border: none;
  border-radius: 30px;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  padding: 18px 46px;
  transition: all 0.3s ease 0s;
}
.subscribe-wrapper .subscribe-form button i {
  font-size: 18px;
  padding-left: 5px;
}

/* end - cta- newsletter */

/* end single blog page */

/* form */

.bg-form {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

input.form-control:focus {
  outline: none;
  border: #3f51b5 1px solid;
  -webkit-box-shadow: none;
  box-shadow: none;
}

select.form-control:focus {
  outline: none;
  border: #3f51b5 1px solid;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* hr */

hr.divisor {
  border-top: 1px solid #8c8b8b;
  text-align: center;
}
hr.divisor:after {
  content: 'OR';
  display: inline-block;
  position: relative;
  top: -24px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  color: #8c8b8b;
  font-size: 18px;
}

/* wizard */

.offerform {
  padding: 30px 20px;
  margin-bottom: 60px;
}

.setup-content {
  overflow: hidden;
}

.setup-content .form-group {
  margin-bottom: 20px;
}

.offerform h3 {
  font-size: 1.2rem;
}

.offerform label.control-label {
  font-size: 0.8rem;
  font-weight: 700;
}

.offerform .prevBtn,
.offerform .nextBtn {
  padding: 12px 26px;
}

.offerform .nextBtn {
  margin-left: 20px;
}

.stepwizard {
  display: none;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.stepwizard:before {
  content: '';
  top: 18px;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: rgba(204, 204, 204, 0.3);
  z-index: 0;
}
.stepwizard-step {
  text-align: center;
  margin-right: 40px;
  z-index: 1;
}

.stepwizard-step .btn {
  background-color: #fff;
  border-color: #dee2e6;
}

.stepwizard-step .btn.active {
  background-color: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}

@media (min-width: 768px) {
  .stepwizard {
    display: flex;
    position: relative;
    justify-content: flex-start;
    margin-bottom: 32px;
  }

  .offerform {
    padding: 40px 80px;
  }

  .setup-content .form-group {
    margin-bottom: 30px;
  }
}

body.get-offer {
  background-color: #f0efed;
  padding-top: 0;
}

@media (min-width: 992px) {
  body.get-offer {
    //   background: url(img/house-interior-opt.jpg) no-repeat center center fixed ;
    background-size: cover;
    min-height: 100vh;
  }
}

/* end - wizard */

/* step 1 */

.holder-style {
  display: block;
  padding: 12px 8px;
  color: #111111;
  background-color: #f8f9fa;
  border: 2px solid #dee2e6;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  transition: all 0.15s ease;
  text-decoration: none;
  margin-bottom: 0;
}

:checked + .holder-style,
.holder-style:hover {
  background-color: #fff;
  border: 2px solid #3f51b5;
  color: #3f51b5;
}

/* end step 1 */

/* step - 2 */

input[type='checkbox'].switch {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2.5em;
  height: 1.3em;
  border: 1px solid #ccc;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].switch:checked {
  border: 1px solid #3f51b5;
}

input[type='checkbox'].switch:after {
  position: absolute;
  content: '';
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: #ced4da;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  right: 1px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].switch:checked:after {
  left: calc(100% - 1.3em);
  background: #3f51b5;
}

/* end - step 2 */

.content-sm {
  max-width: 600px;
  margin: 0 auto;
}

.content-md {
  max-width: 900px;
  margin: 0 auto;
}

.content-lg {
  max-width: 1000px;
  margin: 0 auto;
}

section.offer-result {
  // background: url(img/house-interior.jpg) no-repeat center center fixed;
  background-position-y: 45px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section.offer-result .container {
  height: 900px;
}

.heading {
  font-weight: 700;
}

.subheading {
  font-weight: 300;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footer {
  background-color: #282828;
  font-size: 0.85rem;
  color: #ced4da;
}
.footer .list-inline {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.footer .divider {
  border: rgba(255, 255, 255, 0.4) 1px solid;
}

.footer-logo {
  max-width: 200px;
  height: auto;
}

.footer--title {
  text-transform: uppercase;
  color: #fff;
}

.footer ul {
  padding: 0;
}

.footer ul a {
  color: #ced4da;
}

.footer-bar {
  padding: 20px 0;
}

.avatar {
  border-radius: 50%;
}

/* single */
.single h1 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 3rem;
}

/* content */
/*.content{
    font-family: 'Nunito', sans-serif;
    line-height: 2;
    color: #495057 ;
    padding: 0 15px ;
  }*/

.content {
  padding: 1rem;
}

.content strong {
  font-weight: 700;
}

.content p {
  margin-bottom: 1.5rem;
  line-height: 2;
  letter-spacing: 0.5px;
  color: #495057;
}

.content h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 3rem;
  color: #111111;
}

.content h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 1.7rem;
  color: #111111;
}

.content ol {
  list-style-position: inside;
  padding-left: 0;
}

.content ol li {
  border-left: 5px solid #3f51b5;
  padding-left: 0.75rem;
  margin-bottom: 1.3rem;
  margin-left: 0.12rem;
  line-height: 1;
}

.content ul {
  list-style-position: inside;
  padding-left: 0;
}

.content ul li {
  list-style-type: none;
  position: relative;
  color: #495057;
  margin-bottom: 1rem;
  line-height: 2;
  letter-spacing: 0.5px;
}

.bullets li {
  list-style-type: none;
}

.single-content ul li::before,
.bullets li::before {
  content: ' ';
  background-color: #3f51b5;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: inline-block;
  vertical-align: 0.1em;
  margin-right: 0.7rem;
}

.sidebar {
  padding: 0 15px;
  width: 100%;
}

.wp-block-quote {
  border: 2px solid #8ce99a;
  padding: 30px;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
  position: relative;
  margin: 3rem 0;
}

.wp-block-quote::after {
  content: '';
  position: absolute;
  top: -25px;
  left: 55px;
  width: 50px;
  height: 50px;
  border: 2px solid #8ce99a;
  border-radius: 50%;
  background-color: #fff;
  // background-image: url('img/left-quote-black.png') ;
  background-position: center center;
  background-repeat: no-repeat;
}

.wp-block-quote p {
  margin-bottom: 1rem;
}

.wp-block-quote cite {
  color: #2b8a3e;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  font-style: normal;
  padding-left: 10px;
}

.wp-block-quote cite::before {
  content: '-';
}

@media (min-width: 768px) {
  .content {
    width: calc(100% - 320px);
  }

  .sidebar {
    width: 300px;
  }

  .wp-block-quote {
    padding: 40px 60px;
  }
}

@media (min-width: 992px) {
  .content {
    width: calc(100% - 350px);
  }

  .sidebar {
    width: 320px;
  }
}
.content h2 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 3rem;
  color: #111111;
}

.content h3 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 1.7rem;
  color: #111111;
}

.card--category {
  margin-bottom: 5px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  color: #3f51b5;
}
.card--title,
.card--title:hover,
.card--title:active,
.card--title:focus {
  color: #111111;
  font-weight: 400;
  text-decoration: none;
}

.page-section.latest-news {
  padding-top: 100px;
  padding-bottom: 80px;
  background-color: #f8f9fa;
}

.text-success {
  color: #3f51b5 !important;
}
.bg-success {
  background-color: #3f51b5 !important;
}

.link-underline {
  color: rgba(100, 100, 100, 0.9);
  text-decoration: underline;
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
}

.link-underline:hover {
  color: rgba(100, 100, 100, 0.9);
}

/* Locations template */

.locations {
  // background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url(img/bg-city.jpg);
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(img/bg-city.jpg);
  background-position: center center;
  background-size: cover;
  padding-top: 222px;
}

.locations-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.95rem !important;
}

.locations-container .quick-links div {
  padding: 10px 0;
}

.locations-container .quick-links a {
  color: rgba(100, 100, 100, 0.9);
  text-decoration: none;
}

.locations-container .quick-links a:hover {
  color: #3f51b5;
}

/* 404 error template */

.error-404 img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
}

.dashed-title {
  border-bottom: 1px #dee2e6 solid;
  text-align: left;
  height: 30px;
  width: 100%;
}

.dashed-title span {
  background: #fff;
  padding-right: 15px;
  font-size: 2rem;
  font-weight: bold;
}

#dashed-subtitle {
  background: #fff;
  padding-right: 30px;
  font-size: 1.25rem;
  font-weight: bold;
  color: rgba(100, 100, 100, 0.5);
}

/* How-it-works template */

.how-it-works-header {
  // background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url(img/bg-how-it-works.jpg);
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(img/bg-how-it-works.jpg);
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 992px) {
  .how-it-works-header {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) {
  .how-it-works-header {
    padding-top: 200px;
  }
}

.how-it-works-header .play-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  line-height: 1;
  text-align: center;
  color: #eaeaea;
  background-color: #3f51b5;
  vertical-align: center;
}

.how-it-works-header .play-btn-text {
  margin-top: 12px;
  font-size: 1.5rem;
}

.how-it-works-container {
  padding-top: 150px;
  padding-bottom: 185px;
}

.how-it-works-section img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.how-it-works-step {
  background-color: #f8f9fa;
  padding-left: 5rem !important;
  padding-right: 2rem !important;
  padding-bottom: 5rem !important;
}

.how-it-works-step p {
  color: rgba(100, 100, 100, 0.9);
  font-weight: lighter;
  line-height: 1.7em;
}

.how-it-works-separation {
  height: 125px;
}

.how-it-works-separation .border-right-dashed {
  border-right: 2px #3f51b5 dashed !important;
}

.page-template-template-state .masthead,
.page-template-template-city .masthead {
  // background: url(img/city.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.page-template-template-state .masthead:before,
.page-template-template-city .masthead:before {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.4))
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.page-template-template-city-section {
  padding-bottom: 80px !important;
}

.page-template-template-state .featured,
.page-template-template-city .featured {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
}

.Best-way-to-sell-my-house .col-lg-6 img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.Benefits .Benefits-content {
  line-height: 2rem;
}

@media (min-width: 992px) {
  .page-template-template-state .masthead,
  .page-template-template-city .masthead {
    background-size: cover;
  }

  .Benefits .Benefits-content {
    width: 470px;
  }
}

@media (min-width: 1200px) {
  .Benefits .Benefits-content {
    width: 585px;
  }
}

.testimonials .card {
  margin-right: 20px;
  margin-left: 20px;
}

.testimonials .card-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  margin-bottom: 1.5rem;
}

.page-template-template-state .card-deck .details,
.page-template-template-city .card-deck .details {
  font-size: 12px;
}

.page-template-template-state .bullets,
.page-template-template-city .bullets {
  padding-left: 0px;
}

.page-template-template-state .bullets li,
.page-template-template-city .bullets li {
  padding-top: 0.5rem;
}

.page-template-template-state .we-make-easy .quotes,
.page-template-template-city .we-make-easy .quotes {
  padding: 3rem 3rem 2rem 3rem;
  background-color: #fff;
  border: 2px solid #3f51b5;
}

.page-template-template-state .we-make-easy .quotes .autor,
.page-template-template-city .we-make-easy .quotes .autor {
  padding-top: 0.75rem;
  color: #3f51b5;
}

.we-make-easy {
  background-color: #f8f9fa !important;
  text-align: justify !important;
  padding-top: 120px;
}

.card-img-overlay {
  background-color: rgba(#000, 0.4);
}

.avatar {
  border: 0.1rem solid rgba(#fff, 0.3);
}

.rounded-circle {
  padding: 0 !important;
  border: none !important;
  display: table-cell;
  height: 25px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  background: #d3d3d4;
  color: #212529 !important;
}

.btn-border {
  color: #d3d9df;
  background-color: transparent;
  background-image: none;
  border-color: #d3d9df;
}

.nav-phone {
  padding: 5px 12px !important;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #3f51b5;
}

.fa-2x {
  font-size: 1em !important;
}

.svg-inline--fa.fa-w-16 {
  width: 1.5em;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.stepsform {
  font-family: 'Lato', sans-serif;
  border: 1px solid #3f51b5;
  background-color: #fff;
  min-height: 213px;
}

.stepsform .offer-steps {
  padding: 1.5rem 1.5rem;
  padding-bottom: 0;
}

@media (min-width: 500px) {
  .stepsform .offer-steps .stepwizard {
    display: flex;
    position: relative;
    justify-content: flex-start;
    margin-bottom: unset;
  }
}

.stepsform .offer-steps .stepwizard:before {
  width: 90%;
}

.stepsform .offer-steps .stepwizard .stepwizard-step {
  margin-right: 10px;
}

.stepsform .offer-steps .stepwizard .stepwizard-step:last-child {
  margin-right: 0px;
}

.stepsform .offer-steps .stepwizard .stepwizard-step .btn {
  font-size: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 930px) {
  .stepsform .offer-steps .stepwizard .stepwizard-step {
    margin-right: 21px;
  }

  .stepsform .offer-steps .stepwizard .stepwizard-step .btn {
    font-size: 10px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.stepsform .offer-steps .stepwizard .stepwizard-step a:hover {
  cursor: pointer;
}

.stepsform .content {
  padding: 1.2rem 1.5rem;
  width: 100% !important;
}

.stepsform .content legend {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0;
  padding: 0;
  border-bottom: none;
  color: #3f51b5;
}

@media (min-width: 768px) {
  .stepsform .content legend {
    font-size: 1.5rem;
  }
}

.stepsform .content img {
  vertical-align: -0.125em;
}

.stepsform .content .btn {
  font-size: 0.95rem;
  width: 100%;
}

@media (min-width: 992px) {
  .stepsform .content .btn {
    width: unset;
    margin-left: 1rem;
  }
}

.stepsform .content div.tab label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
}

.stepsform .content div.tab div.row div {
  padding-right: 5px;
  padding-left: 5px;
}

.stepsform .content div.tab div.row div:last-child {
  padding-right: 10px;
}

.stepsform .content div.tab input {
  display: inline-block;
}

.stepsform .content div.tab #zipcode {
  width: 100%;
}

.stepsform .content div.tab #btn-zipcode {
  display: none;
}

.stepsform .content div.tab .col-btn-zipcode-12 {
  display: block;
}

@media (min-width: 992px) {
  .stepsform .content div.tab #zipcode {
    width: 60%;
  }
  .stepsform .content div.tab #btn-zipcode {
    display: inline-block;
  }
  .stepsform .content div.tab .col-btn-zipcode-12 {
    display: none;
  }
}

.stepsform .content div.tab .form-control:valid {
  background-color: #f8f9fa;
}

.stepsform input.error,
.stepsform select.error {
  border: 1px solid red !important;
}

/* Hide all steps by default: */
.stepsform .tab {
  display: none;
}

.stepsform h4,
.stepsform h6 {
  margin: 0 !important;
  zoom: 80%;
}

.stepsform .form-control {
  font-size: 0.8rem;
}

.stepsform .select-rounded-pill {
  border-radius: 50rem;
  appearance: none;
}

@media (min-width: 768px) {
  .stepsform h4,
  .stepsform h6 {
    zoom: 100%;
  }
  .stepsform .form-control {
    font-size: unset;
  }
}

.stepsform .content h3 {
  margin-bottom: 1rem !important;
  color: #3f51b5 !important;
}

.stepsform .another-house-sell {
  color: #3f51b5 !important;
  text-decoration: underline !important;
  text-decoration-style: dotted !important;
  text-underline-position: under !important;
}

/*banner into post*/

.banner {
  background-color: #ebfbee;
  padding: 1rem;
  text-align: center;
}

.banner a {
  color: #349847;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 1px dashed #349847;
}

/* Comments */

.comment-respond a {
  color: #3f51b5;
}

.comment-respond a:hover {
  color: #3f51b5;
}

.comments .avatar {
  width: 48px;
  height: auto;
  margin-right: 0.75rem;
  border-radius: 50% !important;
}

.comment-author {
  display: inline-block;
}

.comment-meta {
  display: inline-block;
  font-style: italic;
}

.comment-text,
.children {
  padding-left: 4.1em !important;
  margin-top: -10px;
}

.reply {
  margin-top: -20px;
  padding-left: 5.5em !important;
  font-size: 12px;
}

.reply a {
  color: #6c757d;
  text-decoration: none;
}

.reply a:hover {
  color: #3f51b5;
}

.comments .comment {
  margin-top: 25px;
}

.comments .comment:first-child {
  margin-top: 0;
}

.comments .children .comment {
  margin-top: 40px;
  border-top: none;
}

.comments ul li::before {
  display: none !important;
}

.comment-respond {
  margin-top: 3rem;
}

.comment-respond .comment-reply-title {
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
}

.comment-respond input[type='text'],
.comment-respond textarea {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.comment-respond textarea {
  height: unset;
}

.comment-respond .comment-form-cookies-consent label {
  font-size: 13px;
  padding-left: 5px;
}

.comment-respond p label {
  font-size: 1.1rem;
  font-weight: bold;
}

.comment-respond input[type='submit'] {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  border: 1px solid transparent;
  padding: 0.375rem 0;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.section-row {
  margin-top: 5.5rem;
}

.post-author {
  padding: 2rem;
  border: 1px solid #dee2e6 !important;
}

.post-author img {
  width: 48px;
  height: auto;
}

.post-author p {
  margin: 0 0 20px !important;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.post-author .media-heading h3 {
  margin: 0px 0 15px !important;
}

.post-comments .media-heading h4 {
  text-transform: capitalize;
}

.post-comments .media-heading .time {
  font-size: 13px;
  margin-right: 15px;
  color: #a7b3c6;
}

.post-comments .media-heading .reply {
  font-size: 13px;
  color: #a7b3c6;
  -webkit-transition: 0.2s color;
  transition: 0.2s color;
}

.post-author .author-social {
  margin-top: 15px;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.post-author .author-social li {
  display: inline-block;
  margin-right: 5px;
}

.content .post-author ul li::before {
  display: none !important;
}

.section-row .section-title {
  margin-bottom: 40px;
}

.dropcap:first-letter {
  float: left;
  font-size: 4em;
  font-weight: 500;
  color: #3f51b5;
  margin: -2rem 0.1em -2.75rem 0;
}

.wp-block-image figcaption {
  color: #868e96 !important;
  font-style: oblique;
  font-size: 14px;
  margin: 1rem 0;
}

.wp-block-image {
  margin: 2rem 0;
  border-bottom: 1px solid #dee2e6;
}

.wp-block-image img {
  width: 100%;
}

.divider-content {
  display: table;
  font-size: 8px;
  text-align: center;
  width: 50%;
  margin: 5rem auto;
}
.divider-content span {
  display: table-cell;
  position: relative;
  line-height: 1.5;
}
.divider-content span:first-child,
.divider-content span:last-child {
  width: 50%;
  top: 13px;
  -moz-background-size: 100% 1px;
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider-content span:first-child {
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#ced4da),
    to(#ced4da)
  );
  background-image: -webkit-linear-gradient(180deg, #ced4da, #ced4da);
  background-image: -moz-linear-gradient(180deg, #ced4da, #ced4da);
  background-image: -o-linear-gradient(180deg, #ced4da, #ced4da);
  background-image: linear-gradient(90deg, #ced4da, #ced4da);
}
.divider-content span:nth-child(2) {
  color: #ced4da;
  padding: 0px 5px;
  width: auto;
  white-space: nowrap;
  letter-spacing: 1rem;
  padding-left: 1em;
}
.divider-content span:last-child {
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#ced4da),
    to(#ced4da)
  );
  background-image: -webkit-linear-gradient(180deg, #ced4da, #ced4da);
  background-image: -moz-linear-gradient(180deg, #ced4da, #ced4da);
  background-image: -o-linear-gradient(180deg, #ced4da, #ced4da);
  background-image: linear-gradient(90deg, #ced4da, #ced4da);
}

.letter-spacing {
  letter-spacing: 2px;
}

#get-offer-sidebar {
  background-color: #ebfbee;
}

.fixed {
  position: fixed;
  top: 20px;
  margin-left: 720px;
  background-color: #0f0 !important;
}

.social-media-sharing a:hover {
  text-decoration: none;
}

.text-grey {
  color: #666666 !important;
}

.subtitle {
  font-size: 1.1rem !important;
}

h5 {
  line-height: 1.75 !important;
}

.btn-lg {
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}

.border-green {
  border-color: #3f51b5 !important;
}

.container-sidebar-autor {
  margin-bottom: 120px;
}

@media (min-width: 768px) {
  .border-md-right {
    border-right: 1px solid #dee2e6 !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111111 !important;
}

@media (min-width: 992px) {
  .masthead h1 {
    font-size: 40px;
    text-align: center;
  }

  .home .searchbar,
  .page-template-template-state .searchbar,
  .page-template-template-city .searchbar {
    background-color: #fff;
    border-radius: 50rem;
    padding: 16px;
    z-index: 999;
    box-shadow: 0px 13px 15px -2px #d6d6d6;
    min-width: 80%;
    transform: translateY(50%);
    margin-bottom: 0;
  }
}
